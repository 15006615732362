
@import "helpers/mixin";
@import "helpers/fonts";
@import "helpers/icons";
@import "helpers/settings";

.dashboard-top-outer{
    background: @cHeaderBG;
    padding: 10px 0 20px;
    margin: -30px 0 -20px;
    @media screen and (min-width: 700px){
        background: transparent;
        padding: 0;
        margin: 0;
    }
}

.dashboard-head{
    margin: 0 auto;
    width: 100%;
    max-width: 526px;
    @media screen and (min-width: 700px){
        max-width: 640px;
        width: 91.2222222%;
    }
    @media screen and (min-width: 1280px){
        width: 100%;
    }
    @media screen and (min-width: 1340px){
        max-width: 900px;
        width: 54.054054%;
    }
    &-block{
        margin: 20px -8.88888888888888vw -16px;
        position: relative;
        @media screen and (min-width: 700px){
            margin-bottom: -8px;
        }
    }
    &-outer{
        position: relative;
        opacity: 0;
        .transition(~"opacity .3s ease");
        &._init{
            opacity: 1;
        }
    }
    &__prev{
        display: none;
        @media screen and (min-width: 1280px){
            display: block;
            position: absolute;
            z-index: 10;
            top: 50%;
            right: auto;
            left: 40px;
            width: 50px;
            height: 100px;
            margin: -59px 0 0;
            border-radius: 30px;
            background: @cBlack3;
            border: 0;
            &:before{
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                .icon-dropdownWhite(12px, 12px);
                margin: -6px;
                transform: rotate(90deg);
            }
            opacity: 0.5;
            .transition(~"opacity .15s ease");
            .no-touchevents &:hover{
                opacity: 1;
            }
        }
    }
    &__next{
        display: none;
        @media screen and (min-width: 1280px){
            display: block;
            position: absolute;
            z-index: 10;
            top: 50%;
            left: auto;
            right: 40px;
            width: 50px;
            height: 100px;
            margin: -59px 0 0;
            border-radius: 30px;
            background: @cBlack3;
            border: 0;
            &:before{
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                .icon-dropdownWhite(12px, 12px);
                margin: -6px;
                transform: rotate(-90deg);
            }
            opacity: 0.5;
            .transition(~"opacity .15s ease");
            .no-touchevents &:hover{
                opacity: 1;
            }
        }
    }
    &-outer{
        padding: 0;
        box-sizing: border-box;
        width: 100%;
        @media screen and (min-width: 700px){
            padding: 0 8.88888888888888vw;
        }
    }
    &-list{
        white-space: nowrap;
        width: 100%;
        font-size: 0;
        line-height: 0;
        position: relative;
        ._sliding &{
            .transition(~"left .5s ease");
            pointer-events: none;
        }
    }
    &-item{
        .inline-block(top);
        width: 100%;
        margin-right: 0;
        white-space: normal;
        @media screen and (min-width: 700px){
            margin-right: 30px;
        }
        @media screen and (min-width: 1280px){
            opacity: 0.6;
            .transition(~"opacity .3s ease");
            &._active{
                opacity: 1;
            }
        }
        .no-touchevents &:hover{
            opacity: 1 !important;
        }
    }
    &-dots{
        display: block;
        margin: 0 auto;
        width: auto;
        font-size: 0;
        line-height: 0;
        text-align: center;
        @media screen and (min-width: 700px){
            margin-top: 10px;
        }
        @media screen and (min-width: 1280px){
            margin-bottom: -18px;
        }
    }
    &-dot{
        .inline-block(top);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 1px solid @cGray;
        margin: 0 5px;
        background: transparent;
        cursor: pointer;
        .transition(~"border-color .15s ease, background .15s ease, box-shadow .15s ease");
        &._active{
            background: @cGreen;
            border-color: @cGreen;
        }
        .no-touchevents &:hover{
            box-shadow: 0 1px 3px @opGreen54;
        }
    }
}

.dashboard-slider{
    margin: 20px 0 0;
    display: block;
    position: relative;
    width: 100%;
    @media screen and (min-width: 700px){
        width: auto;
    }
    @media screen and (min-width: 1280px){
        overflow: hidden;
        padding: 28px;
    }
    &--no-text{
        margin: -11px 0 0;
        @media screen and (min-width: 700px){
            margin: 0;
        }
    }
    &-container{
        margin: 50px 0 0;
        &:last-child{
            margin-bottom: 10px;
        }
        @media screen and (min-width: 700px){
            margin: 30px 0 0;
            &+&{
                margin-top: 19px;
            }
        }
        @media screen and (min-width: 1024px){
            margin: 30px 0 0;
            &+&{
                margin-top: 40px;
            }
        }
        @media screen and (min-width: 1280px){
            margin: 40px 0 0;
            &+&{
                margin-top: 40px;
            }
        }
    }
    &-block{
        margin: 0 0 10px;
        @media screen and (min-width: 1340px){
            margin: 0 0 50px;
        }
    }
    &__title{
        display: block;
        position: relative;
        padding: 1px 0 0;
        box-sizing: border-box;
        margin: 0 0 -1px;
        @media screen and (min-width: 700px){
            margin: 0;
            padding: 1px 0 4px;
        }
    }
    &__side-button{
        display: none;
        @media screen and (min-width: 700px){
            display: block;
            align-self: flex-start;
            position: relative;
            top: 6px;
        }
    }
    &__bottom-button{
      position: relative;
        display: block;
        margin: 19px 0 0;
        text-align: center;
        @media screen and (min-width: 700px){
            display: none;
        }
    }
    &-outer{
        position: relative;
        width: 82.22222224vw;
        @media screen and (min-width: 700px){
            width: 100%;
        }
        @media screen and (min-width: 1280px){
            margin: -28px;
            width: auto;
            &._sliding{
                margin: 0;
                .dashboard-slider{
                    padding: 0;
                    &-prev{
                        padding: 0;
                        margin-right: 30px;
                    }
                    &-next{
                        padding: 0;
                        margin-left: 30px;
                    }
                    &__prev{
                        right: -10px;
                    }
                    &__next{
                        left: -10px;
                    }
                }
            }
        }
    }
    &-list{
        display: block;
        position: relative;
        top: 0;
        left: 0;
        white-space: nowrap;
        font-size: 0;
        line-height: 0;
        .transition(~"left .5s ease");
        &._immediate{
            .transition(~"left 0s ease");
        }
    }
    &-item{
        .inline-block(top);
        width: 100%;
        margin: 0 15px 0 0;
        white-space: normal;
        -moz-user-select: none;
        -khtml-user-select: none;
        user-select: none;
        box-sizing: border-box;
        @media screen and (min-width: 700px){
            width: calc(~"50% - 15px");
            margin: 0 30px 0 0;
        }
        @media screen and (min-width: 1024px){
            width: calc(~"33.33333% - 20px");
        }
        @media screen and (min-width: 1340px){
            width: calc(~"25% - 22.5px");
        }
        @media screen and (min-width: 1900px){
            width: calc(~"(100% - 120px) / 5");
        }
        @media screen and (min-width: 2500px){
            width: calc(~"(100% - 150px) / 6");
        }
        @media screen and (min-width: 3000px){
            width: calc(~"(100% - 180px) / 7");
        }
        @media screen and (min-width: 3500px){
            width: calc(~"(100% - 210px) / 8");
        }
        @media screen and (min-width: 4000px){
            width: calc(~"(100% - 240px) / 9");
        }
        @media screen and (min-width: 4500px){
            width: calc(~"(100% - 270px) / 10");
        }
        @media screen and (min-width: 5000px){
            width: calc(~"(100% - 300px) / 11");
        }
        @media screen and (min-width: 5500px){
            width: calc(~"(100% - 330px) / 12");
        }
        @media screen and (min-width: 6000px){
            width: calc(~"(100% - 360px) / 13");
        }
        @media screen and (min-width: 6500px){
            width: calc(~"(100% - 390px) / 14");
        }
        @media screen and (min-width: 7000px){
            width: calc(~"(100% - 420px) / 15");
        }
        @media screen and (min-width: 7500px){
            width: calc(~"(100% - 450px) / 16");
        }
        @media screen and (min-width: 8000px){
            width: calc(~"(100% - 480px) / 17");
        }
        @media screen and (min-width: 8500px){
            width: calc(~"(100% - 510px) / 18");
        }
        @media screen and (min-width: 9000px){
            width: calc(~"(100% - 540px) / 19");
        }
        @media screen and (min-width: 9500px){
            width: calc(~"(100% - 570px) / 20");
        }
    }
    &__prev{
        display: none;
        @media screen and (min-width: 1280px){
            display: block;
            position: absolute;
            top: 50%;
            left: auto;
            right: -36px;
            width: 50px;
            height: 50px;
            margin: -25px 0 0;
            background: transparent;
            border: 0;
            border-radius: 50%;
            opacity: 1;
            visibility: visible;
            .transition(~"opacity .15s ease");
            &:before{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                .icon-slider-prev(50px, 50px);
                opacity: 1;
                z-index: 1;
                .transition(~"opacity .15s ease");
                transform: scaleX(1) /* rtl:scaleX(-1) */;
            }
            &:after{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                .icon-slider-prev-over(50px, 50px);
                opacity: 0;
                z-index: 2;
                .transition(~"opacity .15s ease");
                transform: scaleX(1) /* rtl:scaleX(-1) */;
            }
            .no-touchevents &:hover{
                &:before{
                    opacity: 0;
                }
                &:after{
                    opacity: 1;
                }
            }
            &:disabled{
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    &__next{
        display: none;
        @media screen and (min-width: 1280px){
            display: block;
            position: absolute;
            top: 50%;
            right: auto;
            left: -36px;
            width: 50px;
            height: 50px;
            margin: -25px 0 0;
            background: transparent;
            border: 0;
            border-radius: 50%;
            opacity: 1;
            visibility: visible;
            .transition(~"opacity .15s ease");
            &:before{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                .icon-slider-next(50px, 50px);
                opacity: 1;
                z-index: 1;
                .transition(~"opacity .15s ease");
                transform: scaleX(1) /* rtl:scaleX(-1) */;
            }
            &:after{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                .icon-slider-next-over(50px, 50px);
                opacity: 0;
                z-index: 2;
                .transition(~"opacity .15s ease");
                transform: scaleX(1) /* rtl:scaleX(-1) */;
            }
            .no-touchevents &:hover{
                &:before{
                    opacity: 0;
                }
                &:after{
                    opacity: 1;
                }
            }
            &:disabled{
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    &-prev{
        display: block;
        position: absolute;
        top: 0;
        right: 100%;
        left: auto;
        width: 100%;
        padding: 28px;
        box-sizing: border-box;
        text-align: right;
        &-item{
            .inline-block(top);
            position: relative;
            width: calc(~"33.33333% - 20px");
            @media screen and (min-width: 1340px){
                width: calc(~"25% - 22.5px");
            }
            @media screen and (min-width: 1900px){
                width: calc(~"(100% - 120px) / 5");
            }
            @media screen and (min-width: 2500px){
                width: calc(~"(100% - 150px) / 6");
            }
            @media screen and (min-width: 3000px){
                width: calc(~"(100% - 180px) / 7");
            }
            @media screen and (min-width: 3500px){
                width: calc(~"(100% - 210px) / 8");
            }
            @media screen and (min-width: 4000px){
                width: calc(~"(100% - 240px) / 9");
            }
            @media screen and (min-width: 4500px){
                width: calc(~"(100% - 270px) / 10");
            }
            @media screen and (min-width: 5000px){
                width: calc(~"(100% - 300px) / 11");
            }
            @media screen and (min-width: 5500px){
                width: calc(~"(100% - 330px) / 12");
            }
            @media screen and (min-width: 6000px){
                width: calc(~"(100% - 360px) / 13");
            }
            @media screen and (min-width: 6500px){
                width: calc(~"(100% - 390px) / 14");
            }
            @media screen and (min-width: 7000px){
                width: calc(~"(100% - 420px) / 15");
            }
            @media screen and (min-width: 7500px){
                width: calc(~"(100% - 450px) / 16");
            }
            @media screen and (min-width: 8000px){
                width: calc(~"(100% - 480px) / 17");
            }
            @media screen and (min-width: 8500px){
                width: calc(~"(100% - 510px) / 18");
            }
            @media screen and (min-width: 9000px){
                width: calc(~"(100% - 540px) / 19");
            }
            @media screen and (min-width: 9500px){
                width: calc(~"(100% - 570px) / 20");
            }
        }
        &-helper{
            position: absolute;
            width: 100%;
            top: 0;
            left: auto;
            right: 0;
            &:before{
                content: '';
                display: block;
                width: 100%;
                height: 0;
                padding-top: 56.4%;
            }
        }
    }
    &-next{
        display: block;
        position: absolute;
        top: 0;
        left: 100%;
        right: auto;
        width: 100%;
        padding: 28px;
        box-sizing: border-box;
        text-align: left;
        &-item{
            .inline-block(top);
            position: relative;
            width: calc(~"33.33333% - 20px");
            @media screen and (min-width: 1340px){
                width: calc(~"25% - 22.5px");
            }
            @media screen and (min-width: 1900px){
                width: calc(~"(100% - 120px) / 5");
            }
            @media screen and (min-width: 2500px){
                width: calc(~"(100% - 150px) / 6");
            }
            @media screen and (min-width: 3000px){
                width: calc(~"(100% - 180px) / 7");
            }
            @media screen and (min-width: 3500px){
                width: calc(~"(100% - 210px) / 8");
            }
            @media screen and (min-width: 4000px){
                width: calc(~"(100% - 240px) / 9");
            }
            @media screen and (min-width: 4500px){
                width: calc(~"(100% - 270px) / 10");
            }
            @media screen and (min-width: 5000px){
                width: calc(~"(100% - 300px) / 11");
            }
            @media screen and (min-width: 5500px){
                width: calc(~"(100% - 330px) / 12");
            }
            @media screen and (min-width: 6000px){
                width: calc(~"(100% - 360px) / 13");
            }
            @media screen and (min-width: 6500px){
                width: calc(~"(100% - 390px) / 14");
            }
            @media screen and (min-width: 7000px){
                width: calc(~"(100% - 420px) / 15");
            }
            @media screen and (min-width: 7500px){
                width: calc(~"(100% - 450px) / 16");
            }
            @media screen and (min-width: 8000px){
                width: calc(~"(100% - 480px) / 17");
            }
            @media screen and (min-width: 8500px){
                width: calc(~"(100% - 510px) / 18");
            }
            @media screen and (min-width: 9000px){
                width: calc(~"(100% - 540px) / 19");
            }
            @media screen and (min-width: 9500px){
                width: calc(~"(100% - 570px) / 20");
            }
        }
        &-helper{
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            &:before{
                content: '';
                display: block;
                width: 100%;
                height: 0;
                padding-top: 56.4%;
            }
        }
    }
}
html[lang="ru"] .dropdown__head--add-to-list, html[lang="ru"] .dbs__button, html[lang="ru"] .gc__button{
    font: normal 400 12px/21px 'Neue Helvetica Paneuropean 55 Roman', sans-serif /* rtl: normal 400 14px/21px Arial, sans-serif */;
}

.dashboard-live{
    @media screen and (min-width: 700px){
        margin: 20px 0 0;
    }
    @media screen and (min-width: 1280px){
        padding: 38px 28px;
        overflow: hidden;
    }
    &-container{
        display: block;
        background: @cGrayE0;
        position: relative;
        overflow: hidden;
        margin: 40px 0 50px;
        @media screen and (min-width: 700px){
            margin: 30px 0 40px;
        }
        @media screen and (min-width: 1024px){
            margin: 40px 0 40px;
        }
        @media screen and (min-width: 1280px){
            margin: 45px 30px 40px;
            border-radius: 30px;
            .iwrap{
                padding: 0 calc(~"8.88888889vw - 30px");
            }
        }
        &:before{
            content: "";
            display: block;
            position: absolute;
            top: calc(~"50% - 184px");
            left: calc(~"50% - 118px");
            .icon-waves(1223px, 1223px);
            transform: translate(-50%, -50%);
            z-index: 0;
            @media screen and (min-width: 700px){
                top: calc(~"50% - 188px");
                left: calc(~"50% - 292px");
            }
            @media screen and (min-width: 1024px){
                top: calc(~"50% - 188px");
                left: calc(~"50% - 418px");
            }
            @media screen and (min-width: 1280px){
                top: calc(~"50% - 110px");
                left: calc(~"50% - 568px");
            }
            @media screen and (min-width: 1560px){
                top: calc(~"50% - 132px");
                left: calc(~"50% - 567px");
            }
        }
    }
    &-inner{
        padding: 50px 0 40px;
        @media screen and (min-width: 700px){
            padding: 40px 0 40px;
        }
    }
    &-outer{
        position: relative;
        width: 82.22222224vw;
        @media screen and (min-width: 700px){
            width: 100%;
        }
        @media screen and (min-width: 1280px){
            margin: -38px -28px;
            width: auto;
            &._sliding{
                margin: 0;
                .dashboard-live{
                    padding: 0;
                    &-prev{
                        padding: 0;
                        margin-right: 30px;
                    }
                    &-next{
                        padding: 0;
                        margin-left: 30px;
                    }
                    &__prev{
                        right: -10px;
                    }
                    &__next{
                        left: -10px;
                    }
                }
            }
        }
    }
    &-list{
        display: block;
        position: relative;
        top: 0;
        left: 0;
        white-space: nowrap;
        font-size: 0;
        line-height: 0;
        .transition(~"left .5s ease");
        &._immediate{
            .transition(~"left 0s ease");
        }
    }
    &-item{
        .inline-block(top);
        width: 100%;
        margin: 0 15px 0 0;
        white-space: normal;
        -moz-user-select: none;
        -khtml-user-select: none;
        user-select: none;
        box-sizing: border-box;
        @media screen and (min-width: 700px){
            width: calc(~"50% - 15px");
            margin: 0 30px 0 0;
        }
        @media screen and (min-width: 1024px){
            width: calc(~"33.33333% - 20px");
        }
        @media screen and (min-width: 1340px){
            width: calc(~"50% - 15px");
        }
        @media screen and (min-width: 2500px){
            width: calc(~"(100% - 60px) / 3");
        }
        @media screen and (min-width: 3500px){
            width: calc(~"(100% - 90px) / 4");
        }
        @media screen and (min-width: 4500px){
            width: calc(~"(100% - 120px) / 5");
        }
        @media screen and (min-width: 5500px){
            width: calc(~"(100% - 150px) / 6");
        }
        @media screen and (min-width: 6500px){
            width: calc(~"(100% - 180px) / 7");
        }
        @media screen and (min-width: 7500px){
            width: calc(~"(100% - 210px) / 8");
        }
        @media screen and (min-width: 8500px){
            width: calc(~"(100% - 240px) / 9");
        }
        @media screen and (min-width: 9500px){
            width: calc(~"(100% - 270px) / 10");
        }
    }
    &__prev{
        display: none;
        @media screen and (min-width: 1280px){
            display: block;
            position: absolute;
            top: 50%;
            left: auto;
            right: -36px;
            width: 50px;
            height: 50px;
            margin: -25px 0 0;
            background: transparent;
            border: 0;
            border-radius: 50%;
            opacity: 1;
            visibility: visible;
            .transition(~"opacity .15s ease");
            &:before{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                .icon-slider-prev(50px, 50px);
                opacity: 1;
                z-index: 1;
                .transition(~"opacity .15s ease");
                transform: scaleX(1) /* rtl:scaleX(-1) */;
            }
            &:after{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                .icon-slider-prev-over(50px, 50px);
                opacity: 0;
                z-index: 2;
                .transition(~"opacity .15s ease");
                transform: scaleX(1) /* rtl:scaleX(-1) */;
            }
            .no-touchevents &:hover{
                &:before{
                    opacity: 0;
                }
                &:after{
                    opacity: 1;
                }
            }
            &:disabled{
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    &__next{
        display: none;
        @media screen and (min-width: 1280px){
            display: block;
            position: absolute;
            top: 50%;
            right: auto;
            left: -36px;
            width: 50px;
            height: 50px;
            margin: -25px 0 0;
            background: transparent;
            border: 0;
            border-radius: 50%;
            opacity: 1;
            visibility: visible;
            .transition(~"opacity .15s ease");
            &:before{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                .icon-slider-next(50px, 50px);
                opacity: 1;
                z-index: 1;
                .transition(~"opacity .15s ease");
                transform: scaleX(1) /* rtl:scaleX(-1) */;
            }
            &:after{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                .icon-slider-next-over(50px, 50px);
                opacity: 0;
                z-index: 2;
                .transition(~"opacity .15s ease");
                transform: scaleX(1) /* rtl:scaleX(-1) */;
            }
            .no-touchevents &:hover{
                &:before{
                    opacity: 0;
                }
                &:after{
                    opacity: 1;
                }
            }
            &:disabled{
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    &-prev{
        display: block;
        position: absolute;
        top: 0;
        right: 100%;
        left: auto;
        width: 100%;
        padding: 38px 28px;
        box-sizing: border-box;
        text-align: right;
        &-item{
            .inline-block(top);
            position: relative;
            width: calc(~"33.33333% - 20px");
            @media screen and (min-width: 1340px){
                width: calc(~"50% - 15px");
            }
            @media screen and (min-width: 2500px){
                width: calc(~"(100% - 60px) / 3");
            }
            @media screen and (min-width: 3500px){
                width: calc(~"(100% - 90px) / 4");
            }
            @media screen and (min-width: 4500px){
                width: calc(~"(100% - 120px) / 5");
            }
            @media screen and (min-width: 5500px){
                width: calc(~"(100% - 150px) / 6");
            }
            @media screen and (min-width: 6500px){
                width: calc(~"(100% - 180px) / 7");
            }
            @media screen and (min-width: 7500px){
                width: calc(~"(100% - 210px) / 8");
            }
            @media screen and (min-width: 8500px){
                width: calc(~"(100% - 240px) / 9");
            }
            @media screen and (min-width: 9500px){
                width: calc(~"(100% - 270px) / 10");
            }
        }
        &-helper{
            position: absolute;
            width: 100%;
            top: 0;
            left: auto;
            right: 0;
            &:before{
                content: '';
                display: block;
                width: 100%;
                height: 0;
                padding-top: 56.4%;
            }
            @media screen and (min-width: 1340px){
                width: calc(~"50% - 15px");
            }
        }
    }
    &-next{
        display: block;
        position: absolute;
        top: 0;
        left: 100%;
        right: auto;
        width: 100%;
        padding: 38px 28px;
        box-sizing: border-box;
        text-align: left;
        &-item{
            .inline-block(top);
            position: relative;
            width: calc(~"33.33333% - 20px");
            @media screen and (min-width: 1340px){
                width: calc(~"50% - 15px");
            }
            @media screen and (min-width: 2500px){
                width: calc(~"(100% - 60px) / 3");
            }
            @media screen and (min-width: 3500px){
                width: calc(~"(100% - 90px) / 4");
            }
            @media screen and (min-width: 4500px){
                width: calc(~"(100% - 120px) / 5");
            }
            @media screen and (min-width: 5500px){
                width: calc(~"(100% - 150px) / 6");
            }
            @media screen and (min-width: 6500px){
                width: calc(~"(100% - 180px) / 7");
            }
            @media screen and (min-width: 7500px){
                width: calc(~"(100% - 210px) / 8");
            }
            @media screen and (min-width: 8500px){
                width: calc(~"(100% - 240px) / 9");
            }
            @media screen and (min-width: 9500px){
                width: calc(~"(100% - 270px) / 10");
            }
        }
        &-helper{
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            &:before{
                content: '';
                display: block;
                width: 100%;
                height: 0;
                padding-top: 56.4%;
            }
            @media screen and (min-width: 1340px){
                width: calc(~"50% - 15px");
            }
        }
    }
}

.dashboard-closeup{
    &--no-text{
        margin: -11px 0 0;
        @media screen and (min-width: 700px){
            margin: 0;
        }
    }
    @media screen and (min-width: 1280px){
        padding: 28px;
        overflow: hidden;
    }
    &-container{
        display: block;
        background: @cGrayE0;
        position: relative;
        margin: 40px 0 50px;
        @media screen and (min-width: 700px){
            margin: 40px 0 40px;
        }
        @media screen and (min-width: 1280px){
            margin: 45px 30px 40px;
            border-radius: 30px;
            .iwrap{
                padding: 0 calc(~"8.88888889vw - 30px");
            }
        }
    }
    &-inner{
        padding: 50px 0 40px;
        @media screen and (min-width: 700px){
            padding: 40px 0 40px;
        }
    }
    &-outer{
        position: relative;
        width: 82.22222224vw;
        @media screen and (min-width: 700px){
            width: 100%;
        }
        @media screen and (min-width: 1280px){
            margin: -28px;
            width: auto;
            &._sliding{
                margin: 0;
                .dashboard-closeup{
                    padding: 0;
                    &-prev{
                        padding: 0;
                        margin-right: 30px;
                    }
                    &-next{
                        padding: 0;
                        margin-left: 30px;
                    }
                    &__prev{
                        right: -10px;
                    }
                    &__next{
                        left: -10px;
                    }
                }
            }
        }
    }
    &-list{
        display: block;
        position: relative;
        top: 0;
        left: 0;
        white-space: nowrap;
        font-size: 0;
        line-height: 0;
        .transition(~"left .5s ease");
        &._immediate{
            .transition(~"left 0s ease");
        }
    }
    &-item{
        .inline-block(top);
        width: 100%;
        margin: 0 15px 0 0;
        white-space: normal;
        -moz-user-select: none;
        -khtml-user-select: none;
        user-select: none;
        box-sizing: border-box;
        @media screen and (min-width: 700px){
            margin: 0 30px 0 0;
        }
        @media screen and (min-width: 1024px){
            width: calc(~"50% - 15px");
            margin: 0 30px 0 0;
        }
        @media screen and (min-width: 1280px){
            width: calc(~"33.33333% - 20px");
        }
        @media screen and (min-width: 1900px){
            width: calc(~"25% - 22.5px");
        }
        @media screen and (min-width: 2500px){
            width: calc(~"(100% - 120px) / 5");
        }
        @media screen and (min-width: 3000px){
            width: calc(~"(100% - 150px) / 6");
        }
        @media screen and (min-width: 3500px){
            width: calc(~"(100% - 180px) / 7");
        }
        @media screen and (min-width: 4000px){
            width: calc(~"(100% - 210px) / 8");
        }
        @media screen and (min-width: 4500px){
            width: calc(~"(100% - 240px) / 9");
        }
        @media screen and (min-width: 5000px){
            width: calc(~"(100% - 270px) / 10");
        }
        @media screen and (min-width: 5500px){
            width: calc(~"(100% - 300px) / 11");
        }
        @media screen and (min-width: 6000px){
            width: calc(~"(100% - 330px) / 12");
        }
        @media screen and (min-width: 6500px){
            width: calc(~"(100% - 360px) / 13");
        }
        @media screen and (min-width: 7000px){
            width: calc(~"(100% - 390px) / 14");
        }
        @media screen and (min-width: 7500px){
            width: calc(~"(100% - 420px) / 15");
        }
        @media screen and (min-width: 8000px){
            width: calc(~"(100% - 450px) / 16");
        }
        @media screen and (min-width: 8500px){
            width: calc(~"(100% - 480px) / 17");
        }
        @media screen and (min-width: 9000px){
            width: calc(~"(100% - 510px) / 18");
        }
        @media screen and (min-width: 9500px){
            width: calc(~"(100% - 540px) / 19");
        }
        @media screen and (min-width: 10000px){
            width: calc(~"(100% - 570px) / 20");
        }
    }
    &__prev{
        display: none;
        @media screen and (min-width: 1280px){
            display: block;
            position: absolute;
            top: 50%;
            left: auto;
            right: -36px;
            width: 50px;
            height: 50px;
            margin: -25px 0 0;
            background: transparent;
            border: 0;
            border-radius: 50%;
            opacity: 1;
            visibility: visible;
            .transition(~"opacity .15s ease");
            &:before{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                .icon-slider-prev(50px, 50px);
                opacity: 1;
                z-index: 1;
                .transition(~"opacity .15s ease");
            }
            &:after{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                .icon-slider-prev-over(50px, 50px);
                opacity: 0;
                z-index: 2;
                .transition(~"opacity .15s ease");
            }
            .no-touchevents &:hover{
                &:before{
                    opacity: 0;
                }
                &:after{
                    opacity: 1;
                }
            }
            &:disabled{
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    &__next{
        display: none;
        @media screen and (min-width: 1280px){
            display: block;
            position: absolute;
            top: 50%;
            right: auto;
            left: -36px;
            width: 50px;
            height: 50px;
            margin: -25px 0 0;
            background: transparent;
            border: 0;
            border-radius: 50%;
            opacity: 1;
            visibility: visible;
            .transition(~"opacity .15s ease");
            &:before{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                .icon-slider-next(50px, 50px);
                opacity: 1;
                z-index: 1;
                .transition(~"opacity .15s ease");
            }
            &:after{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                .icon-slider-next-over(50px, 50px);
                opacity: 0;
                z-index: 2;
                .transition(~"opacity .15s ease");
            }
            .no-touchevents &:hover{
                &:before{
                    opacity: 0;
                }
                &:after{
                    opacity: 1;
                }
            }
            &:disabled{
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    &-prev{
        display: block;
        position: absolute;
        top: 0;
        right: 100%;
        left: auto;
        width: 100%;
        padding: 28px;
        box-sizing: border-box;
        text-align: right;
        &-item{
            .inline-block(top);
            position: relative;
            width: calc(~"33.33333% - 20px");
            @media screen and (min-width: 1900px){
                width: calc(~"25% - 22.5px");
            }
            @media screen and (min-width: 2500px){
                width: calc(~"(100% - 120px) / 5");
            }
            @media screen and (min-width: 3000px){
                width: calc(~"(100% - 150px) / 6");
            }
            @media screen and (min-width: 3500px){
                width: calc(~"(100% - 180px) / 7");
            }
            @media screen and (min-width: 4000px){
                width: calc(~"(100% - 210px) / 8");
            }
            @media screen and (min-width: 4500px){
                width: calc(~"(100% - 240px) / 9");
            }
            @media screen and (min-width: 5000px){
                width: calc(~"(100% - 270px) / 10");
            }
            @media screen and (min-width: 5500px){
                width: calc(~"(100% - 300px) / 11");
            }
            @media screen and (min-width: 6000px){
                width: calc(~"(100% - 330px) / 12");
            }
            @media screen and (min-width: 6500px){
                width: calc(~"(100% - 360px) / 13");
            }
            @media screen and (min-width: 7000px){
                width: calc(~"(100% - 390px) / 14");
            }
            @media screen and (min-width: 7500px){
                width: calc(~"(100% - 420px) / 15");
            }
            @media screen and (min-width: 8000px){
                width: calc(~"(100% - 450px) / 16");
            }
            @media screen and (min-width: 8500px){
                width: calc(~"(100% - 480px) / 17");
            }
            @media screen and (min-width: 9000px){
                width: calc(~"(100% - 510px) / 18");
            }
            @media screen and (min-width: 9500px){
                width: calc(~"(100% - 540px) / 19");
            }
            @media screen and (min-width: 10000px){
                width: calc(~"(100% - 570px) / 20");
            }
        }
        &-helper{
            position: absolute;
            width: 100%;
            top: 0;
            left: auto;
            right: 0;
            &:before{
                content: '';
                display: block;
                width: 100%;
                height: 0;
                padding-top: 56.4%;
            }
        }
    }
    &-next{
        display: block;
        position: absolute;
        top: 0;
        left: 100%;
        right: auto;
        width: 100%;
        padding: 28px;
        box-sizing: border-box;
        text-align: left;
        &-item{
            .inline-block(top);
            position: relative;
            width: calc(~"33.33333% - 20px");
            @media screen and (min-width: 1900px){
                width: calc(~"25% - 22.5px");
            }
            @media screen and (min-width: 2500px){
                width: calc(~"(100% - 120px) / 5");
            }
            @media screen and (min-width: 3000px){
                width: calc(~"(100% - 150px) / 6");
            }
            @media screen and (min-width: 3500px){
                width: calc(~"(100% - 180px) / 7");
            }
            @media screen and (min-width: 4000px){
                width: calc(~"(100% - 210px) / 8");
            }
            @media screen and (min-width: 4500px){
                width: calc(~"(100% - 240px) / 9");
            }
            @media screen and (min-width: 5000px){
                width: calc(~"(100% - 270px) / 10");
            }
            @media screen and (min-width: 5500px){
                width: calc(~"(100% - 300px) / 11");
            }
            @media screen and (min-width: 6000px){
                width: calc(~"(100% - 330px) / 12");
            }
            @media screen and (min-width: 6500px){
                width: calc(~"(100% - 360px) / 13");
            }
            @media screen and (min-width: 7000px){
                width: calc(~"(100% - 390px) / 14");
            }
            @media screen and (min-width: 7500px){
                width: calc(~"(100% - 420px) / 15");
            }
            @media screen and (min-width: 8000px){
                width: calc(~"(100% - 450px) / 16");
            }
            @media screen and (min-width: 8500px){
                width: calc(~"(100% - 480px) / 17");
            }
            @media screen and (min-width: 9000px){
                width: calc(~"(100% - 510px) / 18");
            }
            @media screen and (min-width: 9500px){
                width: calc(~"(100% - 540px) / 19");
            }
            @media screen and (min-width: 10000px){
                width: calc(~"(100% - 570px) / 20");
            }
        }
        &-helper{
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            &:before{
                content: '';
                display: block;
                width: 100%;
                height: 0;
                padding-top: 56.4%;
            }
        }
    }
}

.trending{
    &-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media screen and (min-width: 700px){
            justify-content: space-between;
        }
        // left:0;
    }
    &-item{
        margin: 0 0 30px;
        width: 100%;
        height: auto;
        @media screen and (min-width: 700px){
            width: calc(~"50% - 15px");
        }
    }
}
