/* Full font Helvetica Neue 55 Roman */
.fontHNRegular(@fontSize: 16px, @lineHeight: 28px, @fontWeight: 400, @fontStyle: normal) {
    font: @fontStyle @fontWeight @fontSize/@lineHeight 'Neue Helvetica Paneuropean 55 Roman', sans-serif~"/* rtl: @{fontStyle} @{fontWeight} @{fontSize}/@{lineHeight} Arial, sans-serif */";
}
/* /Full font Helvetica Neue 55 Roman */

/* Full font Helvetica Neue 65 Medium */
.fontHNMedium(@fontSize: 16px, @lineHeight: 28px, @fontWeight: 400, @fontStyle: normal) {
    font: @fontStyle @fontWeight @fontSize/@lineHeight 'Neue Helvetica Paneuropean 65 Medium', sans-serif~"/* rtl: @{fontStyle} @{fontWeight} @{fontSize}/@{lineHeight} Arial, sans-serif */";
}
/* /Full font Helvetica Neue 65 Medium */

/* Full font Helvetica Neue 75 Bold */
.fontHNBold(@fontSize: 16px, @lineHeight: 28px, @fontWeight: 400, @fontStyle: normal) {
    font: @fontStyle @fontWeight @fontSize/@lineHeight 'Neue Helvetica Paneuropean 75 Bold', sans-serif~"/* rtl: @{fontStyle} 600 @{fontSize}/@{lineHeight} Arial, sans-serif */";
}
/* /Full font Helvetica Neue 75 Bold */