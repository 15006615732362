/*.video-js * {
    display: none;
}

.video-js video {
    display: block;
}*/

[dir] .video-js .vjs-control-bar {
    background: #0000 !important;
}

[dir] .video-js .vjs-play-control,
[dir] .video-js .vjs-current-time,
[dir] .video-js .vjs-time-control,
[dir] .video-js .vjs-play-control,
[dir] .video-js .vjs-progress-control,
[dir] .video-js .vjs-live-control,
[dir] .video-js .vjs-seek-to-live-control,
[dir] .video-js .vjs-time-control,
[dir] .video-js .vjs-subs-caps-button,
[dir] .video-js .vjs-fullscreen-control,
[dir] .video-js .vjs-big-play-button,
[dir] .video-js .vjs-picture-in-picture-control {
    display: none;
}

[dir] .vjs-playback-rate.vjs-control{
  position: absolute;
  left: 90px;
  bottom: 21px;
}

[dir] .video-js .vjs-volume-panel {
    display: flex;
    position: absolute;
    right: 10px;
    bottom: 21px;
    z-index: 100;
}


.vjs-waiting .vjs-loading-spinner {
    display: block;
    border: 6px solid rgba(43, 51, 63, .7) !important;
    margin: -25px 0 0 -25px !important;
    visibility: visible;
    top: calc(50% + 25px);
    left: calc(50% + 25px);
}

.video-js.vjs-waiting .vjs-loading-spinner:before,
.video-js.vjs-waiting .vjs-loading-spinner:after {
    -webkit-animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
    animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
}