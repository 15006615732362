/*-- __Icon font
------------------------------------------------------------*/

.icon-font() {
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*-- __Transitions
------------------------------------------------------------*/

/* Transition */
.transition(...) {
    @process_webkit: ~`(function(e){e=e||"all 0 ease 0";var r=["background-size","border-radius","border-bottom-left-radius","border-bottom-right-radius","border-top-left-radius","border-top-right-radius","box-shadow","column","transform","filter"],t="-webkit-",n=/(?:\d)(?:ms|s)/gi,a=/(?:\s|^)(\.?\d+\.?\d*)(?![^(]*\)|\w|%)/gi;return/^[^, ]*,/.test(e)&&(e=e.replace(/(?:,)(?![^(]*\))/g,"")),r.forEach(function(r){-1!==e.indexOf(r)&&(e=e.replace(new RegExp(r,"g"),function(e){return t+e}))}),n.test(e)||"0"===e||(e=e.replace(a,function(e){return e+=parseFloat(e,10)>10?"ms":"s"})),e})((function(){var e="@{arguments}";return e=e.replace(/^\[|\]$/g,"")})())`;
    @process_moz: ~`(function(e){e=e||"all 0 ease 0";var r=["background-size","box-shadow","column","transform","filter"],t="-moz-",n=/(?:\d)(?:ms|s)/gi,a=/(?:\s|^)(\.?\d+\.?\d*)(?![^(]*\)|\w|%)/gi;return/^[^, ]*,/.test(e)&&(e=e.replace(/(?:,)(?![^(]*\))/g,"")),r.forEach(function(r){-1!==e.indexOf(r)&&(e=e.replace(new RegExp(r,"g"),function(e){return t+e}))}),n.test(e)||"0"===e||(e=e.replace(a,function(e){return e+=parseFloat(e,10)>10?"ms":"s"})),e})((function(){var e="@{arguments}";return e=e.replace(/^\[|\]$/g,"")})())`;
    @process_opera: ~`(function(e){e=e||"all 0 ease 0";var r=["transform"],t="-o-",n=/(?:\d)(?:ms|s)/gi,a=/(?:\s|^)(\.?\d+\.?\d*)(?![^(]*\)|\w|%)/gi;return/^[^, ]*,/.test(e)&&(e=e.replace(/(?:,)(?![^(]*\))/g,"")),r.forEach(function(r){-1!==e.indexOf(r)&&(e=e.replace(new RegExp(r,"g"),function(e){return t+e}))}),n.test(e)||"0"===e||(e=e.replace(a,function(e){return e+=parseFloat(e,10)>10?"ms":"s"})),e})((function(){var e="@{arguments}";return e=e.replace(/^\[|\]$/g,"")})())`;
    @process: ~`(function(e){e=e||"all 0 ease 0";var r=["-webkit-","-moz-","-o-",""],t=["column","transform","filter"],n=/(?:\d)(?:ms|s)/gi,a=/(?:\s|^)(\.?\d+\.?\d*)(?![^(]*\)|\w|%)/gi;/^[^, ]*,/.test(e)&&(e=e.replace(/(?:,)(?![^(]*\))/g,""));var c=e.split(/(?:,)(?![^(]*\))/g);return c.forEach(function(e,n){t.forEach(function(t){-1!==e.indexOf(t)&&(c[n]="",r.forEach(function(a,u){c[n]+=e.trim().replace(new RegExp(t,"g"),function(e){return a+e}),u<r.length-1&&(c[n]+=",")}))})}),e=c.join(","),n.test(e)||"0"===e||(e=e.replace(a,function(e){return e+=parseFloat(e,10)>10?"ms":"s"})),e})((function(){var e="@{arguments}";return e=e.replace(/^\[|\]$/g,"")})())`;
    -webkit-transition: @process_webkit;
    -moz-transition: @process_moz;
    -o-transition: @process_opera;
    transition: @process;
}
/* /Transition */


/* Inline block */
.inline-block(@pos: middle) {
    display: inline-block;
    vertical-align: @pos;
}
/* /Inline block */

/* Placeholder */
.placeholder(@color:black, @fontStyle: normal) {
    &::-webkit-input-placeholder {
        color: @color;
        opacity: 1;
        font-style: @fontStyle;
    }
    &:-moz-placeholder {
        color: @color;
        opacity: 1;
        font-style: @fontStyle;
    }
    &::-moz-placeholder {
        color: @color;
        opacity: 1;
        font-style: @fontStyle;
    }
    &:-ms-input-placeholder {
        color: @color;
        opacity: 1;
        font-style: @fontStyle;
    }
}
/* /Placeholder */


/*-- __Triangle
------------------------------------------------------------*/

.triangle-base() {
    display: block;
    width: 0;
    height: 0;
    content: "";
}

.triangle(@direction, @size, @color) {
    .triangle(@direction, @size * 2, @size, @color);
}

.triangle(@direction, @width, @height, @color) when (@direction = up) {
    .triangle-base();
    border-left: (@width / 2) solid transparent;
    border-right: (@width / 2) solid transparent;
    border-bottom: @height solid @color;
    border-top: none;
}

.triangle(@direction, @width, @height, @color) when (@direction = down) {
    .triangle-base();
    border-left: (@width / 2) solid transparent;
    border-right: (@width / 2) solid transparent;
    border-top: @height solid @color;
    border-bottom: none;
}

.triangle(@direction, @width, @height, @color) when (@direction = left) {
    .triangle-base();
    border-top: (@width / 2) solid transparent;
    border-bottom: (@width / 2) solid transparent;
    border-right: @height solid @color;
    border-left: none;
}

.triangle(@direction, @width, @height, @color) when (@direction = right) {
    .triangle-base();
    border-top: (@width / 2) solid transparent;
    border-bottom: (@width / 2) solid transparent;
    border-left: @height solid @color;
    border-right: none;
}
/* /Triangle */

.great-middle() {
    content: "";
    .inline-block();
    height: 100%;
    width: 0;
}

.link-list(@linkColor: black, @linkColorHover:grey) {
    a {
        color: @linkColor;
        .fontPTSerif();
        font-size: 14px;
        line-height: 18px;
        margin: 0 10px;
        position: relative;
        transition: all 0.2s ease;

        .no-touchevents &:hover {
            color: @linkColorHover;
        }

        &:before {
            display: block;
            content: "";
            position: absolute;
            top: 8px;
            left: -14px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: @linkColor;
        }

        &:first-child:before {
            display: none;
        }
    }
}

.animate(@animationName: tilt, @animationTime:1s, @animationFinite: infinite, @animationDelay: 0s, @animationEase: ease) {
    -webkit-animation-name: @animationName;
    -moz-animation-name: @animationName;
    -o-animation-name: @animationName;
    -ms-animation-name: @animationName;
    animation-name: @animationName;
    -webkit-animation-iteration-count: @animationFinite;
    -moz-animation-iteration-count: @animationFinite;
    -o-animation-iteration-count: @animationFinite;
    -ms-animation-iteration-count: @animationFinite;
    animation-iteration-count: @animationFinite;
    -webkit-animation-duration: @animationTime;
    -moz-animation-duration: @animationTime;
    -o-animation-duration: @animationTime;
    -ms-animation-duration: @animationTime;
    animation-duration: @animationTime;
    -webkit-animation-delay: @animationDelay;
    -moz-animation-delay: @animationDelay;
    -o-animation-delay: @animationDelay;
    -ms-animation-delay: @animationDelay;
    animation-delay: @animationDelay;
    -webkit-animation-timing-function: @animationEase;
    -moz-animation-timing-function: @animationEase;
    -o-animation-timing-function: @animationEase;
    -ms-animation-timing-function: @animationEase;
    animation-timing-function: @animationEase;
}

/* No select */
.no-select() {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
/* /No select */

